/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import XButton from 'vux/src/components/x-button';
import XInput from 'vux/src/components/x-input';
export default {
  name: 'ChangePWD',
  components: {
    XInput: XInput,
    XButton: XButton,
    Group: Group
  },
  data: function data() {
    return {
      oldPwd: '',
      //原密码
      newPwd: '',
      //新密码
      confirmPwd: '' //确认密码

    };
  },
  computed: {},
  methods: {
    sumitClick: function sumitClick() {
      var _this = this;

      var reg = /^[0-9a-zA-Z]{6,10}$/;

      if (!reg.test(this.oldPwd)) {
        this.$utils.Tools.toastShow('原密码密码必须为6-10位的数字或字母组合');
        return;
      }

      if (reg.test(this.oldPwd) && reg.test(this.newPwd) && reg.test(this.confirmPwd)) {
        if (this.newPwd == this.confirmPwd) {
          this.$utils.Tools.loadShow();
          this.$api.My.updatePwd({
            userSerial: this.$utils.Store.getItem('userSerial'),
            newPassword: this.newPwd,
            userPassword: this.oldPwd
          }).then(function (response) {
            var code = response.code,
                msg = response.msg;

            _this.$utils.Tools.loadHide();

            if (+code !== _this.$code.success) {
              _this.$utils.Tools.toastShow(msg);
            } else {
              _this.$utils.Tools.toastShow('密码修改成功');

              setTimeout(function () {
                _this.$router.go(-1);
              }, 1000);
            }
          }).catch(function () {
            _this.$utils.Tools.httpError();
          });
        } else {
          this.$utils.Tools.toastShow('新密码输入不一致');
        }
      } else {
        // if (!reg.test(this.oldPwd)) {
        //     this.$utils.Tools.toastShow('旧密码必须为6-10位的数字或字母组合')
        // } else
        if (!reg.test(this.newPwd)) {
          this.$utils.Tools.toastShow('新密码必须为6-10位的数字或字母组合');
        } else if (!reg.test(this.confirmPwd)) {
          this.$utils.Tools.toastShow('二次确认密码必须为6-10位的数字或字母组合');
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};